html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

#app-root {
  height: 100%;
}